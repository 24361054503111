<template>
  <div>
    <div v-editable="blok" class="footer-wrapper flex flex-col gap-2">
      <div v-for="entry in blok.Content" :key="entry._uid">
        <CmsLink
          v-if="isCmsLink(entry)"
          :blok="entry"
          exact-active-color="inherit"
          text-size="md"
          :bold-on-active="false"
          :data-testid="`footer_${entry._uid}`"
          @mousedown="linkClick"
        />
        <!-- Payment Methods -->
        <PaymentProviders
          v-else-if="entry.component === 'PaymentMethodsFooter'"
          variant="small"
          hide-headline
        />
        <!-- One Trust Setting Popupup Trigger -->
        <span
          v-else-if="entry.component === 'OneTrustShowSettingsFooter'"
          class="ot-footer-link cursor-pointer"
          @click="toggleOnetrustInfoDisplay"
        >
          {{ entry.label }}
        </span>
        <div
          v-else-if="entry.component === 'SocialMediaLinksFooter'"
          class="flex items-center gap-4"
        >
          <a
            v-for="link in entry.social_media_link"
            :id="`footer_social_${link.title}`"
            :key="link._uid"
            :href="resolveSbMultilinkUrl(link?.url, $currentShop)"
            :aria-label="link.title"
            :target="'_blank'"
            rel="noopener noreferrer"
            :data-testid="`footer_social_${link.title}`"
            @click="iconClick"
          >
            <FimPicture
              v-if="link.logo"
              provider="local"
              :mobile="link.logo.filename"
              :alt="link.title"
              :aria-label="link.title"
              width="30"
              height="30"
              class="object-contain"
              loading="lazy"
            />
            <span class="sr-only">{{ link.title }}</span>
          </a>
        </div>
        <span v-else-if="isCmsText(entry)">
          <CmsText :blok="entry" />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SbFooterColumn, SbLink, SbText } from '../types/storyblok'
import { resolveSbMultilinkUrl } from '~/storyblok/utils/links'

defineProps({
  blok: {
    type: Object as PropType<SbFooterColumn>,
    default: () => {},
  },
})

const $currentShop = useCurrentShop()

const isCmsLink = (entry: SbFooterColumn['Content'][number]): entry is SbLink =>
  ['CmsLink', 'Link'].includes(entry.component)

const isCmsText = (entry: SbFooterColumn['Content'][number]): entry is SbText =>
  ['CmsText', 'Text'].includes(entry.component)

const { trackFooterIconClick, trackFooterItemClick } = await useTrackingEvents()
const linkClick = (event: MouseEvent) => {
  trackFooterItemClick({ element: event.target as HTMLButtonElement })
}

const iconClick = (event: MouseEvent) => {
  trackFooterIconClick({ element: event.currentTarget as HTMLButtonElement })
}

const toggleOnetrustInfoDisplay = (_event: MouseEvent) => {
  showCookieSettings()
}
</script>

<style lang="scss">
.footer-wrapper {
  color: rgb(200 202 204 / 1);

  h3 {
    color: rgb(237 238 240 / 1);
    margin-bottom: 0;
    margin-top: 0.8rem;
    font-size: 20px;
  }

  b {
    color: rgb(215 215 217 / 1);
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
